@-moz-keyframes hinge {
  0% {
    -moz-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -moz-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -moz-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -moz-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -moz-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -moz-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -moz-transform: rotate3d(0, 0, 1, 80deg);
    -ms-transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -moz-transform: rotate3d(0, 0, 1, 60deg);
    -ms-transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -moz-transform: translate3d(0, 700px, 0);
    -ms-transform: translate3d(0, 700px, 0);
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
/* line 68, ../src/jquery.hosted-payments.sass */
.hinge, .hp .hp-col-right .hp-card-visual.hp-card-visual-flat.hp-card-visual-flat-active {
  -moz-animation-name: hinge;
  -webkit-animation-name: hinge;
  animation-name: hinge;
  -moz-animation-delay: 200ms;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

@-moz-keyframes hp-loading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes hp-loading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hp-loading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}
/* line 91, ../src/jquery.hosted-payments.sass */
.animated-fast {
  -moz-animation-duration: 300ms;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -moz-animation-timing-function: linear-ease-out;
  -webkit-animation-timing-function: linear-ease-out;
  animation-timing-function: linear-ease-out;
}

/* line 96, ../src/jquery.hosted-payments.sass */
.animated, .hp .hp-col-right .hp-card-visual {
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -moz-animation-timing-function: linear-ease-out;
  -webkit-animation-timing-function: linear-ease-out;
  animation-timing-function: linear-ease-out;
}

/* line 101, ../src/jquery.hosted-payments.sass */
.shake, .hp-card-invalid, .hp-bank-invalid, .hp-em-invalid {
  -moz-animation-name: shake;
  -webkit-animation-name: shake;
  animation-name: shake;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* line 105, ../src/jquery.hosted-payments.sass */
div[data-selector-key] {
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: table;
}
/* line 112, ../src/jquery.hosted-payments.sass */
div[data-selector-key]:active, div[data-selector-key]:focus, div[data-selector-key]:focus-within, div[data-selector-key]:hover {
  -moz-box-shadow: 0px 0px 0px 2px rgba(33, 150, 243, 0.3);
  -webkit-box-shadow: 0px 0px 0px 2px rgba(33, 150, 243, 0.3);
  box-shadow: 0px 0px 0px 2px rgba(33, 150, 243, 0.3);
}

/* line 118, ../src/jquery.hosted-payments.sass */
.hp {
  all: unset;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: inset 0 0 0 4px #fff, 0 11px 25px -15px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 0 0 4px #fff, 0 11px 25px -15px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 0 4px #fff, 0 11px 25px -15px rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: 400;
  font-size: 16px !important;
  background: linear-gradient(to bottom, #ffffff, #f2f2f2);
  max-width: 644px;
  position: relative;
  border: 1px solid #f3f3f3;
}
/* line 40, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */
.hp div, .hp span, .hp applet, .hp object, .hp iframe,
.hp h1, .hp h2, .hp h3, .hp h4, .hp h5, .hp h6, .hp p, .hp blockquote, .hp pre,
.hp a, .hp abbr, .hp acronym, .hp address, .hp big, .hp cite, .hp code,
.hp del, .hp dfn, .hp em, .hp img, .hp ins, .hp kbd, .hp q, .hp s, .hp samp,
.hp small, .hp strike, .hp strong, .hp sub, .hp sup, .hp tt, .hp var,
.hp b, .hp u, .hp i, .hp center,
.hp dl, .hp dt, .hp dd, .hp ol, .hp ul, .hp li,
.hp fieldset, .hp form, .hp label, .hp legend,
.hp table, .hp caption, .hp tbody, .hp tfoot, .hp thead, .hp tr, .hp th, .hp td,
.hp article, .hp aside, .hp canvas, .hp details, .hp embed,
.hp figure, .hp figcaption, .hp footer, .hp header, .hp hgroup,
.hp menu, .hp nav, .hp output, .hp ruby, .hp section, .hp summary,
.hp time, .hp mark, .hp audio, .hp video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
/* line 55, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */
.hp table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* line 57, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */
.hp caption, .hp th, .hp td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
/* line 59, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */
.hp q, .hp blockquote {
  quotes: none;
}
/* line 103, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */
.hp q:before, .hp q:after, .hp blockquote:before, .hp blockquote:after {
  content: "";
  content: none;
}
/* line 61, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */
.hp a img {
  border: none;
}
/* line 135, ../src/jquery.hosted-payments.sass */
.hp hr {
  margin: 30px;
  padding: 0;
  border: 0 none;
  height: 1px;
  background: #dedede;
}
/* line 142, ../src/jquery.hosted-payments.sass */
.hp strong {
  font-weight: bold;
}
/* line 145, ../src/jquery.hosted-payments.sass */
.hp .hide,
.hp .hp-page {
  display: none !important;
}
/* line 149, ../src/jquery.hosted-payments.sass */
.hp .show,
.hp .hp-page-active {
  display: block !important;
}
/* line 153, ../src/jquery.hosted-payments.sass */
.hp .hp-emoney-logo {
  margin: 4px 1px;
}
/* line 156, ../src/jquery.hosted-payments.sass */
.hp .hp-captcha-container {
  -moz-box-shadow: inset 1px 1px 0 #f3f3f3, inset -1px -1px 0 #f3f3f3;
  -webkit-box-shadow: inset 1px 1px 0 #f3f3f3, inset -1px -1px 0 #f3f3f3;
  box-shadow: inset 1px 1px 0 #f3f3f3, inset -1px -1px 0 #f3f3f3;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  z-index: 11;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.93);
  border: 1px solid #9e9e9e;
}
/* line 171, ../src/jquery.hosted-payments.sass */
.hp .hp-captcha-container.hp-captcha-container-active {
  display: block;
}
/* line 174, ../src/jquery.hosted-payments.sass */
.hp .hp-captcha-container #hp-captcha-selector {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* line 180, ../src/jquery.hosted-payments.sass */
.hp .hp-captcha-container [data-hcaptcha-widget-id],
.hp .hp-captcha-container > div > div {
  margin: 0 auto;
  display: block;
}
/* line 185, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container,
.hp .hp-error-container,
.hp .hp-app-link-container {
  -moz-box-shadow: inset 1px 1px 0 #f3f3f3, inset -1px -1px 0 #f3f3f3;
  -webkit-box-shadow: inset 1px 1px 0 #f3f3f3, inset -1px -1px 0 #f3f3f3;
  box-shadow: inset 1px 1px 0 #f3f3f3, inset -1px -1px 0 #f3f3f3;
  position: absolute;
  display: none;
  z-index: 10;
}
/* line 194, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container.hp-loading-container-active, .hp .hp-loading-container.hp-error-container-active, .hp .hp-loading-container.hp-page-active,
.hp .hp-error-container.hp-loading-container-active,
.hp .hp-error-container.hp-error-container-active,
.hp .hp-error-container.hp-page-active,
.hp .hp-app-link-container.hp-loading-container-active,
.hp .hp-app-link-container.hp-error-container-active,
.hp .hp-app-link-container.hp-page-active {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.93);
  border: 1px solid #9e9e9e;
}
/* line 206, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container.hp-error-container-active,
.hp .hp-error-container.hp-error-container-active,
.hp .hp-app-link-container.hp-error-container-active {
  min-height: 420px;
}
/* line 209, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-error-text,
.hp .hp-error-container .hp-error-text,
.hp .hp-app-link-container .hp-error-text {
  font-size: 3em;
  color: #f44336;
  margin: 30px 0 0 30px;
  display: block;
}
/* line 215, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-error-disclaimer,
.hp .hp-error-container .hp-error-disclaimer,
.hp .hp-app-link-container .hp-error-disclaimer {
  width: 390px;
  margin: 30px;
}
@media screen and (max-width: 656px) {
  /* line 215, ../src/jquery.hosted-payments.sass */
  .hp .hp-loading-container .hp-error-disclaimer,
  .hp .hp-error-container .hp-error-disclaimer,
  .hp .hp-app-link-container .hp-error-disclaimer {
    width: auto;
  }
}
/* line 222, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-error-message,
.hp .hp-error-container .hp-error-message,
.hp .hp-app-link-container .hp-error-message {
  font-size: 2em;
  margin: 0 0 0 30px;
  color: #9e9e9e;
}
/* line 227, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-loading-text,
.hp .hp-error-container .hp-loading-text,
.hp .hp-app-link-container .hp-loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  width: 200px;
  margin-left: -100px;
  text-align: center;
  text-transform: uppercase;
  color: #2196f3;
}
@media screen and (max-width: 656px) {
  /* line 227, ../src/jquery.hosted-payments.sass */
  .hp .hp-loading-container .hp-loading-text,
  .hp .hp-error-container .hp-loading-text,
  .hp .hp-app-link-container .hp-loading-text {
    display: none;
  }
}
/* line 241, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-loading,
.hp .hp-error-container .hp-loading,
.hp .hp-app-link-container .hp-loading {
  position: absolute;
  line-height: 40px;
  top: 50%;
  left: 50%;
  margin-left: -28.66667px;
  margin-top: -35px;
}
/* line 253, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-loading span,
.hp .hp-error-container .hp-loading span,
.hp .hp-app-link-container .hp-loading span {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin: 2.66667px;
  border-radius: 50%;
  z-index: 10;
  background: #4285f4;
  -moz-animation: hp-loading 0.4s infinite alternate;
  -webkit-animation: hp-loading 0.4s infinite alternate;
  animation: hp-loading 0.4s infinite alternate;
}
/* line 265, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-loading span:nth-of-type(2),
.hp .hp-error-container .hp-loading span:nth-of-type(2),
.hp .hp-app-link-container .hp-loading span:nth-of-type(2) {
  background: #db4437;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
/* line 270, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-loading span:nth-of-type(3),
.hp .hp-error-container .hp-loading span:nth-of-type(3),
.hp .hp-app-link-container .hp-loading span:nth-of-type(3) {
  background: #f4b400;
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
/* line 275, ../src/jquery.hosted-payments.sass */
.hp .hp-loading-container .hp-loading span:nth-of-type(4),
.hp .hp-error-container .hp-loading span:nth-of-type(4),
.hp .hp-app-link-container .hp-loading span:nth-of-type(4) {
  background: #0f9d58;
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
/* line 280, ../src/jquery.hosted-payments.sass */
.hp .hp-submit {
  margin: 0;
  padding: 0;
  border: 0;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-shadow: 0 1px 1px #0C70C0;
  background: #0C70C0;
  border: 1px solid #0C70C0;
  color: #f3f3f3;
  display: block;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  outline: none;
  position: relative;
  top: 0;
  margin-top: 8px;
}
@media screen and (max-width: 656px) {
  /* line 280, ../src/jquery.hosted-payments.sass */
  .hp .hp-submit {
    font-size: 12px;
  }
}
/* line 304, ../src/jquery.hosted-payments.sass */
.hp .hp-submit:focus, .hp .hp-submit:hover {
  outline: none;
  background: #309df4;
  border: 1px solid #0d78ce;
}
/* line 309, ../src/jquery.hosted-payments.sass */
.hp .hp-submit:active {
  -moz-box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.25);
  top: 1px;
}
/* line 314, ../src/jquery.hosted-payments.sass */
.hp .hp-submit:disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  top: 1px;
  cursor: default;
}
/* line 321, ../src/jquery.hosted-payments.sass */
.hp .hp-submit.hp-submit-danger {
  background: #d2190b;
  border: 1px solid #ea1c0d;
  text-shadow: 0 1px 1px #ea1c0d;
}
/* line 326, ../src/jquery.hosted-payments.sass */
.hp .hp-submit.hp-submit-danger:focus, .hp .hp-submit.hp-submit-danger:hover {
  outline: none;
  background: #f65e53;
  border: 1px solid #f43e31;
}
/* line 331, ../src/jquery.hosted-payments.sass */
.hp .hp-submit.hp-submit-success {
  background: #57be00;
  border: 1px solid #49a000;
  text-shadow: 0 1px 1px #49a000;
}
/* line 336, ../src/jquery.hosted-payments.sass */
.hp .hp-submit.hp-submit-success:focus, .hp .hp-submit.hp-submit-success:hover {
  outline: none;
  background: #5ece00;
  border: 1px solid #4eaa00;
}
/* line 341, ../src/jquery.hosted-payments.sass */
.hp .hp-input-wrapper {
  margin: 24px auto 0;
  position: relative;
  max-width: 400px;
}
/* line 346, ../src/jquery.hosted-payments.sass */
.hp .hp-input-code-message {
  background: #fff;
  padding: 10px 14px;
  color: #FF2400 !important;
  border: 1px solid #FF2400;
  border-top: 3px solid #FF2400;
  font-size: 100% !important;
}
/* line 354, ../src/jquery.hosted-payments.sass */
.hp .hp-input-code-message strong {
  font-weight: bold !important;
}
@media screen and (max-width: 656px) {
  /* line 346, ../src/jquery.hosted-payments.sass */
  .hp .hp-input-code-message {
    margin-top: 0 !important;
  }
}
/* line 360, ../src/jquery.hosted-payments.sass */
.hp .hp-input-container {
  overflow: hidden;
  position: relative;
  display: inline-block;
}
/* line 365, ../src/jquery.hosted-payments.sass */
.hp .hp-input-container.hp-input-container-ach {
  *zoom: 1;
  overflow: visible;
  width: 100%;
  margin-top: 12px;
  padding-top: 6px;
  border-top: 1px solid #9e9e9e;
}
/* line 38, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/utilities/general/_clearfix.scss */
.hp .hp-input-container.hp-input-container-ach:after {
  content: "";
  display: table;
  clear: both;
}
/* line 374, ../src/jquery.hosted-payments.sass */
.hp .hp-input-container.hp-input-container-date {
  *zoom: 1;
  overflow: visible;
  width: 50%;
}
/* line 38, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/utilities/general/_clearfix.scss */
.hp .hp-input-container.hp-input-container-date:after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 656px) {
  /* line 374, ../src/jquery.hosted-payments.sass */
  .hp .hp-input-container.hp-input-container-date {
    width: 100%;
    margin-top: 6px;
  }
}
/* line 385, ../src/jquery.hosted-payments.sass */
.hp .hp-input-container.hp-input-container-date .hp-input-month,
.hp .hp-input-container.hp-input-container-date .hp-input-year {
  overflow: visible;
  *zoom: 1;
}
/* line 38, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/utilities/general/_clearfix.scss */
.hp .hp-input-container.hp-input-container-date .hp-input-month:after,
.hp .hp-input-container.hp-input-container-date .hp-input-year:after {
  content: "";
  display: table;
  clear: both;
}
/* line 391, ../src/jquery.hosted-payments.sass */
.hp .hp-input-container.hp-input-container-date .hp-input-month.hp-input-active,
.hp .hp-input-container.hp-input-container-date .hp-input-year.hp-input-active {
  background: linear-gradient(to bottom, #f1cd00 -54%, #fffceb 6px);
}
/* line 394, ../src/jquery.hosted-payments.sass */
.hp .hp-input {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-shadow: 0 1px 1px #fff;
  -webkit-box-shadow: 0 1px 1px #fff;
  box-shadow: 0 1px 1px #fff;
  margin-top: 6px;
  border: 1px solid #9e9e9e;
  display: inline-block;
  background: #fff;
  position: relative;
  height: 48px;
}
/* line 406, ../src/jquery.hosted-payments.sass */
.hp .hp-input > input,
.hp .hp-input > select {
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  display: block;
  border: 0 none;
  background: linear-gradient(to bottom, #a9a9a9 -54%, #ffffff 6px);
  min-height: 100%;
  width: 100%;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  text-indent: 14px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 422, ../src/jquery.hosted-payments.sass */
.hp .hp-input > input:hover, .hp .hp-input > input:focus,
.hp .hp-input > select:hover,
.hp .hp-input > select:focus {
  outline: none;
}
/* line 426, ../src/jquery.hosted-payments.sass */
.hp .hp-input > select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
/* line 430, ../src/jquery.hosted-payments.sass */
.hp .hp-input > input:hover, .hp .hp-input > input:focus {
  background: linear-gradient(to bottom, #f1cd00 -54%, #fffceb 6px);
  outline: none;
}
/* line 436, ../src/jquery.hosted-payments.sass */
.hp .hp-input > select:hover, .hp .hp-input > select:focus {
  cursor: pointer;
  background: linear-gradient(to bottom, #f1cd00 -54%, #fffceb 6px);
  outline: none;
}
/* line 442, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-error {
  border-color: #f44336;
  -moz-box-shadow: inset 0px 0px 1px #f44336, 0px 0px 1px #f44336 !important;
  -webkit-box-shadow: inset 0px 0px 1px #f44336, 0px 0px 1px #f44336 !important;
  box-shadow: inset 0px 0px 1px #f44336, 0px 0px 1px #f44336 !important;
}
/* line 446, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-fullname {
  width: 100%;
}
/* line 449, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-bank, .hp .hp-input.hp-input-ach-type {
  width: 60%;
}
/* line 454, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-ach-type {
  width: calc(40% - 6px);
  margin-left: 6px;
}
/* line 458, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-email, .hp .hp-input.hp-input-password, .hp .hp-input.hp-input-code {
  width: 100%;
  display: block;
  margin: 7px auto 0;
}
/* line 463, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-left {
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
  width: 50%;
  float: left;
  border-right: 0 none;
}
/* line 470, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-right {
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
  width: 50%;
  float: left;
}
/* line 476, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-cc {
  width: 50%;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
  border-right: 0 none;
}
@media screen and (max-width: 656px) {
  /* line 476, ../src/jquery.hosted-payments.sass */
  .hp .hp-input.hp-input-cc {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #9e9e9e;
  }
}
/* line 491, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-name {
  width: 50%;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
}
@media screen and (max-width: 656px) {
  /* line 491, ../src/jquery.hosted-payments.sass */
  .hp .hp-input.hp-input-name {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #9e9e9e;
  }
}
/* line 504, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-month {
  width: 49%;
  margin-top: 0;
  background: #f3f3f3;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
  border-right: 0 none;
}
@media screen and (max-width: 656px) {
  /* line 504, ../src/jquery.hosted-payments.sass */
  .hp .hp-input.hp-input-month {
    width: 50%;
  }
}
/* line 512, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-month:hover, .hp .hp-input.hp-input-month:focus, .hp .hp-input.hp-input-month:active {
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSItNTQlIiBzdG9wLWNvbG9yPSIjZjFjZDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmY2ViIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 6px;
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(-54%, #f1cd00), color-stop(100%, #fffceb)) 6px;
  background: -moz-linear-gradient(top, #f1cd00 -54%, #fffceb) 6px;
  background: -webkit-linear-gradient(top, #f1cd00 -54%, #fffceb) 6px;
  background: linear-gradient(to bottom, #f1cd00 -54%, #fffceb) 6px;
}
/* line 523, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-year {
  width: 49%;
  margin-top: 0;
  background: #f3f3f3;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
}
@media screen and (max-width: 656px) {
  /* line 523, ../src/jquery.hosted-payments.sass */
  .hp .hp-input.hp-input-year {
    width: 50%;
  }
}
/* line 531, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-year:hover, .hp .hp-input.hp-input-year:focus, .hp .hp-input.hp-input-year:active {
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSItNTQlIiBzdG9wLWNvbG9yPSIjZjFjZDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmY2ViIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 6px;
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(-54%, #f1cd00), color-stop(100%, #fffceb)) 6px;
  background: -moz-linear-gradient(top, #f1cd00 -54%, #fffceb) 6px;
  background: -webkit-linear-gradient(top, #f1cd00 -54%, #fffceb) 6px;
  background: linear-gradient(to bottom, #f1cd00 -54%, #fffceb) 6px;
}
/* line 540, ../src/jquery.hosted-payments.sass */
.hp .hp-input.hp-input-cvv {
  width: 50%;
}
@media screen and (max-width: 656px) {
  /* line 540, ../src/jquery.hosted-payments.sass */
  .hp .hp-input.hp-input-cvv {
    width: 100%;
    margin-top: 6px;
  }
}
/* line 549, ../src/jquery.hosted-payments.sass */
.hp.hp-active {
  display: block;
}
/* line 552, ../src/jquery.hosted-payments.sass */
.hp h3 {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  padding: 17px 11px 15px;
}
/* line 560, ../src/jquery.hosted-payments.sass */
.hp h2 {
  color: #2E3037;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
}
/* line 568, ../src/jquery.hosted-payments.sass */
.hp .text-muted {
  color: #9e9e9e;
  font-size: 17px;
  text-align: center;
  font-weight: 300;
  position: relative;
  margin: 14px 0;
  line-height: normal;
}
/* line 577, ../src/jquery.hosted-payments.sass */
.hp a,
.hp a:link,
.hp a:focus,
.hp a:visited {
  color: #2196f3;
  text-decoration: none;
}
/* line 584, ../src/jquery.hosted-payments.sass */
.hp a:hover,
.hp a:link:hover,
.hp a:focus:hover,
.hp a:visited:hover {
  color: #0C70C0;
}
/* line 587, ../src/jquery.hosted-payments.sass */
.hp .hp-row {
  *zoom: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid #9e9e9e;
}
/* line 38, ../../../../../../../tools/ruby26/lib/ruby/gems/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/utilities/general/_clearfix.scss */
.hp .hp-row:after {
  content: "";
  display: table;
  clear: both;
}
/* line 595, ../src/jquery.hosted-payments.sass */
.hp .hp-row:after {
  clear: both;
}
/* line 598, ../src/jquery.hosted-payments.sass */
.hp .hp-pull-left {
  float: left;
}
/* line 601, ../src/jquery.hosted-payments.sass */
.hp .hp-pull-right {
  float: right;
}
/* line 604, ../src/jquery.hosted-payments.sass */
.hp .hp-clearfix {
  overflow: hidden;
  *zoom: 1;
}
/* line 607, ../src/jquery.hosted-payments.sass */
.hp .hp-col {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
/* line 612, ../src/jquery.hosted-payments.sass */
.hp .hp-break {
  overflow: hidden;
  *zoom: 1;
  clear: both;
}
/* line 617, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left {
  width: 200px;
  margin: 0 0 13px 0;
  min-height: 460px;
}
@media screen and (max-width: 656px) {
  /* line 617, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left {
    width: 64px;
    margin: 0;
  }
}
/* line 627, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left p {
  color: #6b6b6b;
}
/* line 630, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-nav {
  list-style: none;
  -moz-box-shadow: 0 2px 0 #fff;
  -webkit-box-shadow: 0 2px 0 #fff;
  box-shadow: 0 2px 0 #fff;
}
@media screen and (max-width: 656px) {
  /* line 630, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav {
    margin: 0;
  }
  /* line 638, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav li.hp-type {
    margin: 0;
    text-align: center;
  }
  /* line 642, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav li.hp-type a {
    text-indent: 0px !important;
    border-bottom: 1px solid #9e9e9e;
    padding: 14px;
  }
  /* line 647, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav li.hp-type a > img {
    display: block;
    position: static !important;
    margin: 0 auto;
  }
}
/* line 655, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-nav .hp-type.hp-active a {
  background: #f3f3f3;
  -moz-box-shadow: inset -4px 0 #2196f3;
  -webkit-box-shadow: inset -4px 0 #2196f3;
  box-shadow: inset -4px 0 #2196f3;
}
/* line 660, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-nav .hp-type:last-child a {
  border-bottom: 1px solid #9e9e9e;
}
/* line 663, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-nav .hp-type:first-child a {
  -moz-border-radius: 3px 0 0 0;
  -webkit-border-radius: 3px;
  border-radius: 3px 0 0 0;
}
/* line 666, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-nav .hp-type a {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #9e9e9e;
  text-indent: 50px;
  background: #fff;
  font-size: 14px;
}
/* line 680, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-nav .hp-type a span {
  display: block;
}
@media screen and (max-width: 656px) {
  /* line 666, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav .hp-type a {
    height: 22px;
    line-height: 22px;
    text-indent: 22px;
    overflow: hidden !important;
  }
  /* line 691, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav .hp-type a span {
    display: none;
  }
  /* line 694, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-nav .hp-type a i.hp-sprite {
    left: 10px;
  }
}
/* line 697, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure {
  width: 200px;
  position: absolute;
  bottom: 22px;
  left: 0;
  font-size: 11px;
}
/* line 704, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-secure-wrapper {
  padding: 0 10px;
}
@media screen and (max-width: 656px) {
  /* line 697, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-left .hp-secure {
    display: none;
  }
}
/* line 710, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-secure-bottom {
  overflow: hidden;
  *zoom: 1;
  display: block;
  color: #6b6b6b;
  border-top: 1px solid #9e9e9e;
  margin-top: 7px;
  padding-top: 7px;
}
/* line 719, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-secure-bottom > div {
  font-weight: bold;
}
/* line 722, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-secure-bottom .hp-secure-bottom-left {
  float: left;
}
/* line 725, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-secure-bottom .hp-secure-bottom-right {
  float: right;
}
/* line 728, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-cards {
  width: 200px;
}
/* line 731, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-cards-icons {
  background-image: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/sprite.png");
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 3px;
  margin-top: 0;
  background-color: #9e9e9e;
}
/* line 740, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-version-charge {
  color: #2196f3;
}
/* line 743, ../src/jquery.hosted-payments.sass */
.hp .hp-col-left .hp-secure .hp-version-refund {
  color: #f44336;
}
/* line 746, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right {
  width: 100%;
  margin-left: -200px;
  padding-left: 200px;
}
@media screen and (max-width: 656px) {
  /* line 746, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right {
    margin-left: -64px;
    padding-left: 64px;
  }
}
/* line 756, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  border-left: 1px solid #9e9e9e;
  padding: 22px 13px;
  min-height: 473px;
  -moz-box-shadow: inset 1px 0 #fff;
  -webkit-box-shadow: inset 1px 0 #fff;
  box-shadow: inset 1px 0 #fff;
}
@media screen and (max-width: 656px) {
  /* line 756, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-content {
    padding-top: 14px;
  }
}
/* line 770, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content.hp-content-active {
  display: block;
}
/* line 774, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-card-mastercard .hp-card-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/background-mastercard.png");
}
/* line 778, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-card-jcb .hp-card-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/background-jcb.png");
}
/* line 782, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-card-visa .hp-card-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/background-visa.png");
}
/* line 786, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-card-discover .hp-card-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/background-discover.png");
}
/* line 790, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-card-amex .hp-card-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/background-amex.png");
}
/* line 794, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-card-emoney .hp-card-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/background-emoney.png");
}
/* line 797, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background-color: #6b6b6b;
  border: 0 none;
  display: block;
  height: 236px;
  width: 398px;
  position: relative;
  margin: 0 auto 0;
}
@media screen and (max-width: 656px) {
  /* line 797, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual {
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    -webkit-background-size: cover !important;
    background-size: cover !important;
  }
}
@media screen and (max-width: 420px) {
  /* line 797, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual {
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    height: 127px;
    width: 100%;
    font-size: 14px;
  }
  /* line 823, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-name {
    bottom: 34px !important;
    font-size: 14px !important;
    left: 25px !important;
  }
  /* line 828, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-number {
    font-size: 14px !important;
    top: 50px !important;
    left: 25px !important;
  }
  /* line 833, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-label-alt {
    display: none !important;
  }
  /* line 836, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-expiry {
    bottom: 16px !important;
    right: -3px !important;
    width: 75px !important;
  }
  /* line 841, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-value {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 500px) {
  /* line 797, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual {
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    font-size: 14px;
    height: 127px;
    width: 100%;
  }
  /* line 853, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-name {
    bottom: 34px !important;
    font-size: 14px !important;
    left: 25px !important;
  }
  /* line 858, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-number {
    font-size: 14px !important;
    top: 50px !important;
    left: 25px !important;
  }
  /* line 863, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-label-alt {
    display: none !important;
  }
  /* line 866, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-expiry {
    bottom: 16px !important;
    right: -3px !important;
    width: 75px !important;
  }
  /* line 871, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-value {
    font-size: 14px !important;
  }
}
/* line 875, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual > div {
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear;
  position: absolute;
  font-size: 29px;
  text-align: left;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !important;
  font-weight: 600;
  color: #fff;
}
/* line 885, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-logo {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  top: 30px;
  right: 0px;
}
/* line 892, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-number {
  top: 100px;
  left: 35px;
}
/* line 896, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-name {
  font-size: 23px;
  bottom: 50px;
  left: 35px;
  font-weight: 400;
  text-transform: uppercase;
}
/* line 903, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-expiry {
  bottom: 45px;
  right: 15px;
  width: 75px;
}
/* line 908, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-label,
.hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-label-alt {
  position: absolute;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 400;
}
/* line 915, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-label {
  top: -40px;
}
/* line 918, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-label-alt {
  width: 45px;
  text-align: right;
  left: -52px;
  top: -28px;
}
/* line 924, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual .hp-card-visual-expiry-value {
  position: absolute;
  font-size: 22px;
  text-transform: uppercase;
  top: -30px;
}
/* line 930, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual.hp-card-visual-flat {
  background: #6b6b6b;
  border: 0 none;
}
/* line 934, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual.hp-card-visual-flat > div {
  color: #fff;
}
/* line 937, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual.hp-card-visual-flat.hp-card-visual-flat-active {
  border-width: 2px;
  border-color: #6ce56c;
}
/* line 943, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-card-visual.hp-card-visual-flat.hp-card-visual-flat-active > div {
  text-shadow: none;
  color: #6ce56c;
}
/* line 948, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault {
  position: relative;
}
/* line 951, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault .hp-transvault-visual-image {
  text-align: center;
  margin-top: 24px;
}
/* line 955, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault .hp-submit-refresh {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 4px;
  border: 1px solid #50AF00;
  height: 24px;
  width: 24px;
  background: rgba(80, 175, 0, 0.1);
}
/* line 969, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault .hp-submit-refresh:hover {
  background: rgba(80, 175, 0, 0.2);
}
/* line 972, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault .hp-submit-refresh img.hp-submit-refresh-img {
  width: 100%;
}
/* line 975, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault .hp-submit {
  position: absolute;
  bottom: 45px;
  top: auto;
  left: 50%;
  right: auto;
  margin: 0 0 0 -170px;
  width: 340px;
}
/* line 984, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-transvault p.hp-input-transvault-message {
  width: 100%;
  font-size: 2em;
  text-align: center;
  color: #2196f3;
  margin-top: -14px;
  min-width: 400px;
}
/* line 992, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  height: 173px;
  width: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 656px) {
  /* line 992, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-bank-visual {
    -moz-transform:  scale(0.49, 0.49) rotateZ(0) translate(50%, 0) skew(0, 0);
    -ms-transform:  scale(0.49, 0.49) rotateZ(0) translate(50%, 0) skew(0, 0);
    -webkit-transform:  scale(0.49, 0.49) rotateZ(0) translate(50%, 0) skew(0, 0);
    transform:  scale(0.49, 0.49) rotateZ(0) translate(50%, 0) skew(0, 0);
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    margin-top: -70px;
  }
}
@media screen and (max-width: 420px) {
  /* line 992, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-bank-visual {
    -moz-transform:  scale(0.49, 0.49) rotateZ(0) translate(0, 0) skew(0, 0);
    -ms-transform:  scale(0.49, 0.49) rotateZ(0) translate(0, 0) skew(0, 0);
    -webkit-transform:  scale(0.49, 0.49) rotateZ(0) translate(0, 0) skew(0, 0);
    transform:  scale(0.49, 0.49) rotateZ(0) translate(0, 0) skew(0, 0);
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
/* line 1010, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual > div {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !important;
  position: absolute;
  font-size: 20px;
  color: #f3f3f3;
}
/* line 1016, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual .hp-bank-visual-image {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/sprite.png");
  height: 173px;
  width: 400px;
  background-color: #9e9e9e;
}
/* line 1022, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual .hp-bank-visual-name {
  top: 37px;
  left: 23px;
  text-transform: uppercase;
}
/* line 1027, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual .hp-bank-visual-left {
  bottom: 31px;
  left: 26px;
  font-size: 16px;
}
/* line 1032, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual .hp-bank-visual-right {
  bottom: 31px;
  left: 186px;
  font-size: 16px;
}
/* line 1037, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-bank-visual .hp-bank-visual-bank {
  top: 19px;
  left: 20px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  color: #476d6d;
  text-shadow: 0 1px 1px #74c4bb;
}
/* line 1047, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank .slider-container {
  padding-top: 30px;
}
/* line 1055, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank p,
.hp .hp-col-right .hp-content-cc p,
.hp .hp-col-right .hp-content-emoney p,
.hp .hp-col-right .hp-content-transvault p,
.hp .hp-col-right .hp-content-code p {
  font-size: 12px;
  color: #6b6b6b;
  text-align: center;
  margin: 24px 0 0;
}
/* line 1061, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank h4,
.hp .hp-col-right .hp-content-cc h4,
.hp .hp-col-right .hp-content-emoney h4,
.hp .hp-col-right .hp-content-transvault h4,
.hp .hp-col-right .hp-content-code h4 {
  font-size: 16px;
  color: #919191;
  text-align: center;
}
/* line 1066, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank .callout,
.hp .hp-col-right .hp-content-cc .callout,
.hp .hp-col-right .hp-content-emoney .callout,
.hp .hp-col-right .hp-content-transvault .callout,
.hp .hp-col-right .hp-content-code .callout {
  background: rgba(255, 255, 255, 0.25);
  padding: 17px;
  margin: 14px 0;
}
@media screen and (max-width: 656px) {
  /* line 1066, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-content-bank .callout,
  .hp .hp-col-right .hp-content-cc .callout,
  .hp .hp-col-right .hp-content-emoney .callout,
  .hp .hp-col-right .hp-content-transvault .callout,
  .hp .hp-col-right .hp-content-code .callout {
    display: none;
  }
}
/* line 1075, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank .callout p,
.hp .hp-col-right .hp-content-cc .callout p,
.hp .hp-col-right .hp-content-emoney .callout p,
.hp .hp-col-right .hp-content-transvault .callout p,
.hp .hp-col-right .hp-content-code .callout p {
  margin: 0 0 17px;
  padding: 0;
}
/* line 1079, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank .hp-code-title,
.hp .hp-col-right .hp-content-cc .hp-code-title,
.hp .hp-col-right .hp-content-emoney .hp-code-title,
.hp .hp-col-right .hp-content-transvault .hp-code-title,
.hp .hp-col-right .hp-content-code .hp-code-title {
  text-align: center;
  font-size: 20px;
  position: relative;
  z-index: 1;
  margin: 20px 0;
}
@media screen and (max-width: 656px) {
  /* line 1079, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-content-bank .hp-code-title,
  .hp .hp-col-right .hp-content-cc .hp-code-title,
  .hp .hp-col-right .hp-content-emoney .hp-code-title,
  .hp .hp-col-right .hp-content-transvault .hp-code-title,
  .hp .hp-col-right .hp-content-code .hp-code-title {
    margin: 5px 0 15px;
  }
}
/* line 1089, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank .hp-input-cvv-image,
.hp .hp-col-right .hp-content-cc .hp-input-cvv-image,
.hp .hp-col-right .hp-content-emoney .hp-input-cvv-image,
.hp .hp-col-right .hp-content-transvault .hp-input-cvv-image,
.hp .hp-col-right .hp-content-code .hp-input-cvv-image {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/icon-cvvalt.png");
  height: 33px;
  width: 50px;
  position: absolute;
  top: 7px;
  right: 7px;
}
/* line 1098, ../src/jquery.hosted-payments.sass */
.hp .hp-col-right .hp-content-bank.hp-content-card-amex .hp-input-cvv-image,
.hp .hp-col-right .hp-content-cc.hp-content-card-amex .hp-input-cvv-image,
.hp .hp-col-right .hp-content-emoney.hp-content-card-amex .hp-input-cvv-image,
.hp .hp-col-right .hp-content-transvault.hp-content-card-amex .hp-input-cvv-image,
.hp .hp-col-right .hp-content-code.hp-content-card-amex .hp-input-cvv-image {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/icon-cvv.png");
}
/* line 1101, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt {
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.93);
  border: 1px solid #9e9e9e;
  opacity: 0;
}
/* line 1115, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt.active {
  opacity: 1;
}
/* line 1118, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-container {
  padding: 15px;
  position: relative;
}
/* line 1122, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-container .hp-close {
  position: absolute;
  top: 0;
  right: 15px;
  color: #2196f3;
}
/* line 1128, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-container > p {
  font-size: 1.6em;
  color: #2196f3;
  display: block;
  margin-bottom: 15px;
}
/* line 1134, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-container hr {
  margin: 0 0 15px 0;
  padding: 0;
  border: 0 none;
  height: 1px;
  background: #dedede;
}
/* line 1141, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-container a {
  text-align: center;
  display: block;
  margin: 15px auto;
}
@media screen and (max-width: 656px) {
  /* line 1147, ../src/jquery.hosted-payments.sass */
  .hp .hp-avs-prompt-container > p {
    font-size: 18px;
    margin-bottom: 7px;
  }
  /* line 1151, ../src/jquery.hosted-payments.sass */
  .hp .hp-avs-prompt-container hr {
    margin: 3px 0 12px;
  }
  /* line 1154, ../src/jquery.hosted-payments.sass */
  .hp .hp-avs-prompt-container a {
    margin: 7px auto;
    font-size: 12px;
  }
}
/* line 1158, ../src/jquery.hosted-payments.sass */
.hp .hp-label-avs {
  display: block;
  font-size: 1.1em;
  color: #1f1f1f;
}
@media screen and (max-width: 656px) {
  /* line 1158, ../src/jquery.hosted-payments.sass */
  .hp .hp-label-avs {
    font-size: 14px;
  }
}
/* line 1166, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-right,
.hp .hp-avs-prompt-left {
  display: block;
  margin-bottom: 15px;
}
@media screen and (max-width: 656px) {
  /* line 1166, ../src/jquery.hosted-payments.sass */
  .hp .hp-avs-prompt-right,
  .hp .hp-avs-prompt-left {
    margin-bottom: 4px;
  }
}
/* line 1174, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-prompt-right {
  margin-bottom: 0;
}
/* line 1177, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-street {
  width: 100%;
}
/* line 1180, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-street > input {
  width: 100%;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 1185, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-state {
  width: 142px;
}
/* line 1188, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-state > select {
  width: 100%;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 1193, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-zip {
  width: 144px;
}
/* line 1196, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-zip > input {
  width: 100%;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 1201, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-city {
  width: 234px;
}
/* line 1204, ../src/jquery.hosted-payments.sass */
.hp .hp-input-avs-city > input {
  width: 100%;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 1209, ../src/jquery.hosted-payments.sass */
.hp .hp-pull-left {
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 656px) {
  /* line 1209, ../src/jquery.hosted-payments.sass */
  .hp .hp-pull-left {
    margin-bottom: 4px;
  }
}
/* line 1217, ../src/jquery.hosted-payments.sass */
.hp .hp-pull-left.hp-no-margins {
  margin: 0;
}
/* line 1220, ../src/jquery.hosted-payments.sass */
.hp .hp-avs-required {
  color: #f44336;
}

/* line 1223, ../src/jquery.hosted-payments.sass */
.hp-form-emoney,
.hp-form-code {
  position: relative;
}

/* line 1228, ../src/jquery.hosted-payments.sass */
.hp-form-success .hp-success-visual {
  background: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/icon-success.png");
  font: 0/0 serif;
  text-shadow: none;
  color: transparent;
  height: 128px;
  width: 128px;
  margin: 85px auto 30px;
}
@media screen and (max-width: 656px) {
  /* line 1238, ../src/jquery.hosted-payments.sass */
  .hp-form-success .hp-success-visual {
    margin-top: 15px;
  }
  /* line 1241, ../src/jquery.hosted-payments.sass */
  .hp-form-success .text-muted {
    display: none;
  }
}

/* line 1244, ../src/jquery.hosted-payments.sass */
.hp-form-transvault-app-link {
  height: 84px;
}
/* line 1247, ../src/jquery.hosted-payments.sass */
.hp-form-transvault-app-link .hp-row {
  display: none;
}
/* line 1250, ../src/jquery.hosted-payments.sass */
.hp-form-transvault-app-link .hp-submit-redirect {
  padding: 16px 0;
  display: block;
  color: #fff !important;
  width: 90%;
  margin: 14px auto 0;
}

@media screen and (max-width: 656px) {
  /* line 1258, ../src/jquery.hosted-payments.sass */
  .hp.hp-form .hp-submit.hp-submit-danger {
    bottom: 4vh;
    left: 0;
    margin: 0 8vw;
    width: 80%;
  }

  /* line 1264, ../src/jquery.hosted-payments.sass */
  .hp .hp-col-right .hp-content-transvault p.hp-input-transvault-message {
    font-size: 1rem;
    min-width: 300px;
  }

  /* line 1269, ../src/jquery.hosted-payments.sass */
  .hp-form-transvault .hp-content-transvault,
  .hp-form-transvault .hp-col-left {
    min-height: 280px !important;
  }
}
/* line 1273, ../src/jquery.hosted-payments.sass */
.hp-tooltip {
  width: 312px;
  background: #0C70C0;
  border-radius: 6px;
  text-align: center;
  -moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.2);
  margin-left: -156px !important;
}
/* line 1275, ../src/jquery.hosted-payments.sass */
.hp-tooltip span, .hp-tooltip p, .hp-tooltip div {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
/* line 1293, ../src/jquery.hosted-payments.sass */
.hp-tooltip .hp-tooltip-wrapper {
  padding: 10px;
}
/* line 1296, ../src/jquery.hosted-payments.sass */
.hp-tooltip .hp-tooltip-wrapper:before {
  content: " ";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #0C70C0;
}
/* line 1308, ../src/jquery.hosted-payments.sass */
.hp-tooltip .hp-tooltip-title,
.hp-tooltip .hp-tooltip-content {
  display: block;
  color: white;
}
/* line 1313, ../src/jquery.hosted-payments.sass */
.hp-tooltip .hp-tooltip-title {
  font-weight: bold;
}
/* line 1316, ../src/jquery.hosted-payments.sass */
.hp-tooltip .hp-tooltip-close {
  position: absolute;
  top: 0;
  right: 5px;
  color: white;
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
}
/* line 1324, ../src/jquery.hosted-payments.sass */
.hp-tooltip .hp-tooltip-close:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  cursor: pointer;
}

/* line 1329, ../src/jquery.hosted-payments.sass */
.hp-sprite {
  background-image: url("https://cdn.jsdelivr.net/gh/etsms/hosted-payments@latest/dist/images/sprite.png");
  background-repeat: no-repeat;
  display: block;
}

/* line 1334, ../src/jquery.hosted-payments.sass */
.sprite-background-ach {
  width: 400px;
  height: 173px;
  background-position: -1px -1px;
}

/* line 1339, ../src/jquery.hosted-payments.sass */
.sprite-background-amex {
  width: 398px;
  height: 236px;
  background-position: -1px -176px;
}

/* line 1344, ../src/jquery.hosted-payments.sass */
.sprite-background-diners {
  width: 398px;
  height: 236px;
  background-position: -5px -418px;
}

/* line 1349, ../src/jquery.hosted-payments.sass */
.sprite-background-discover {
  width: 398px;
  height: 236px;
  background-position: -1px -660px;
}

/* line 1354, ../src/jquery.hosted-payments.sass */
.sprite-background-emoney {
  width: 398px;
  height: 236px;
  background-position: -1px -898px;
}

/* line 1359, ../src/jquery.hosted-payments.sass */
.sprite-background-jcb {
  width: 398px;
  height: 236px;
  background-position: -1px -1136px;
}

/* line 1364, ../src/jquery.hosted-payments.sass */
.sprite-background-mastercard {
  width: 398px;
  height: 236px;
  background-position: -1px -1374px;
}

/* line 1369, ../src/jquery.hosted-payments.sass */
.sprite-background-visa {
  width: 398px;
  height: 236px;
  background-position: -1px -1612px;
}

/* line 1374, ../src/jquery.hosted-payments.sass */
.sprite-card-ach {
  width: 42px;
  height: 26px;
  background-position: -1px -1850px;
}

/* line 1379, ../src/jquery.hosted-payments.sass */
.sprite-card-amex {
  width: 42px;
  height: 26px;
  background-position: -1px -1878px;
}

/* line 1384, ../src/jquery.hosted-payments.sass */
.sprite-card-diners {
  width: 42px;
  height: 26px;
  background-position: -1px -1906px;
}

/* line 1389, ../src/jquery.hosted-payments.sass */
.sprite-card-discover {
  width: 42px;
  height: 26px;
  background-position: -1px -1934px;
}

/* line 1394, ../src/jquery.hosted-payments.sass */
.sprite-card-emoney {
  width: 42px;
  height: 26px;
  background-position: -1px -1962px;
}

/* line 1399, ../src/jquery.hosted-payments.sass */
.sprite-card-gift {
  width: 42px;
  height: 26px;
  background-position: -1px -1990px;
}

/* line 1404, ../src/jquery.hosted-payments.sass */
.sprite-card-jcb {
  width: 42px;
  height: 26px;
  background-position: -1px -2018px;
}

/* line 1409, ../src/jquery.hosted-payments.sass */
.sprite-card-mastercard {
  width: 42px;
  height: 26px;
  background-position: -1px -2046px;
}

/* line 1414, ../src/jquery.hosted-payments.sass */
.sprite-card-visa {
  width: 42px;
  height: 26px;
  background-position: -1px -2074px;
}

/* line 1419, ../src/jquery.hosted-payments.sass */
.sprite-icon-cvv {
  width: 50px;
  height: 33px;
  background-position: -1px -2102px;
}

/* line 1424, ../src/jquery.hosted-payments.sass */
.sprite-icon-cvvalt {
  width: 50px;
  height: 33px;
  background-position: -1px -2137px;
}

/* line 1429, ../src/jquery.hosted-payments.sass */
.sprite-icon-refresh {
  width: 24px;
  height: 24px;
  background-position: -1px -2172px;
}

/* line 1434, ../src/jquery.hosted-payments.sass */
.sprite-icon-shield {
  width: 58px;
  height: 64px;
  background-position: -1px -2198px;
}

/* line 1439, ../src/jquery.hosted-payments.sass */
.sprite-icon-success {
  width: 128px;
  height: 128px;
  background-position: -1px -2264px;
}

/* line 1444, ../src/jquery.hosted-payments.sass */
.sprite-logo {
  width: 80px;
  height: 8px;
  background-position: -1px -2394px;
}

/* line 1449, ../src/jquery.hosted-payments.sass */
.sprite-nav-ach {
  width: 39px;
  height: 39px;
  background-position: -1px -2404px;
}

/* line 1454, ../src/jquery.hosted-payments.sass */
.sprite-nav-creditcard {
  width: 39px;
  height: 39px;
  background-position: -1px -2445px;
}

/* line 1459, ../src/jquery.hosted-payments.sass */
.sprite-nav-gift {
  width: 39px;
  height: 39px;
  background-position: -1px -2486px;
}

/* line 1464, ../src/jquery.hosted-payments.sass */
.sprite-nav-sign {
  width: 39px;
  height: 39px;
  background-position: -1px -2527px;
}

/* line 1469, ../src/jquery.hosted-payments.sass */
.sprite-nav-swipe {
  width: 39px;
  height: 39px;
  background-position: -1px -2568px;
}

/* line 1474, ../src/jquery.hosted-payments.sass */
.sprite-nav-transvault {
  width: 39px;
  height: 39px;
  background-position: -1px -2609px;
}

/* line 1479, ../src/jquery.hosted-payments.sass */
.sprite-nav-ach,
.sprite-nav-creditcard,
.sprite-nav-gift,
.sprite-nav-sign,
.sprite-nav-swipe,
.sprite-nav-transvault {
  position: absolute;
  left: 5px;
  top: 5px;
}
